export const constantRouterMap = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index.vue'),
    meta: { title: '首页', target: '', link: '' },
    hidden: true
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/login.vue'),
    meta: { title: '登录', target: '', link: '' },
    hidden: true
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('@/views/notice/index.vue'),
    meta: { title: '消息', target: '', link: '' },
    hidden: true
  },
  {
    path: '/oa/detail',
    name: 'Detail',
    component: () => import('@/views/oa/detail.vue'),
    meta: { title: '流程详情', target: '', link: '' },
    hidden: true
  },
  {
    path: '/oa/approve',
    name: 'Approve',
    component: () => import('@/views/oa/approve.vue'),
    meta: { title: '流程审批', target: '', link: '' },
    hidden: true
  },
  {
    path: '/oa/apply',
    name: 'Apply',
    component: () => import('@/views/oa/launch.vue'),
    meta: { title: '流程发起', target: '', link: '' },
    hidden: true
  },
  {
    path: '/share/:id',
    name: 'Share',
    component: () => import('@/views/share/index.vue'),
    meta: { title: '', target: '', link: '', isWhiteList: true },
    hidden: true
  },
  {
    path: '/404',
    name: 'Error',
    component: () => import('@/views/404.vue'),
    meta: { title: '404', target: '', link: '' },
    hidden: true
  },
  // 客户企业档案新增
  {
    path: '/customer/add',
    name: 'CustomerAdd',
    component: () => import('@/views/customer/detail.vue'),
    meta: { title: '新增客户/企业档案', target: '', link: '' },
    hidden: true
  },
  // 客户企业档案详情
  {
    path: '/customer/details',
    name: 'detail',
    component: () => import('@/views/customer/detail.vue'),
    meta: { title: '客户/企业档案详情', target: '', link: '' },
    hidden: true
  },
  // 客户企业档案审核
  {
    path: '/customer/approve',
    name: 'CustomerApprove',
    component: () => import('@/views/customer/detail.vue'),
    meta: { title: '客户/企业档案审核', target: '', link: '' },
    hidden: true
  },
  // 业务/财务对接人
  {
    path: '/customer/dockingDetails',
    name: 'dockingDetails',
    component: () => import('@/views/customer/docking.vue'),
    meta: { title: '业务/财务对接人详情', target: '', link: '' },
    hidden: true
  },
  {
    path: '/customer/docking',
    name: 'docking',
    component: () => import('@/views/customer/docking.vue'),
    meta: { title: '新增业务/财务对接人', target: '', link: '' },
    hidden: true
  },
  {
    path: '/contract/add',
    name: 'ContractAdd',
    component: () => import('@/views/contract/contractArchives.vue'),
    meta: { title: '新增合同', target: '', link: '' },
    hidden: true
  },
  {
    path: '/contract/detail',
    name: 'ContractDetail',
    component: () => import('@/views/contract/contractArchives.vue'),
    meta: { title: '合同详情', target: '', link: '' },
    hidden: true
  },
  // 新增标签
  {
    path: '/lable/add',
    name: 'addLabel',
    component: () => import('@/views/labelManagement/labelForm.vue'),
    meta: { title: '新增标签', target: '', link: '' },
    hidden: true
  },
  // 编辑标签
  {
    path: '/lable/edit',
    name: 'EditLabel',
    component: () => import('@/views/labelManagement/labelForm.vue'),
    meta: { title: '编辑标签', target: '', link: '' },
    hidden: true
  },
  // 评论输入
  {
    path: '/commentInput/index',
    name: 'commentInput',
    component: () => import('@/views/commentInput/index.vue'),
    meta: { title: '输入评论', target: '', link: '' },
    hidden: true
  }
]
