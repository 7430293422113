<template>
  <van-form ref="form" @submit="onSubmit" :validate-trigger="['onChange', 'onBlur', 'onSubmit']" :submit-on-enter="false" v-bind="$attrs">
    <slot />
  </van-form>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { getTips } from '@/api/formTips/index'
import { FORMTIPS } from '@/utils/tip-config'

const props = defineProps({
  modelValue: Object,
  tip: String
})

const emit = defineEmits(['update:modelValue', 'postData', 'setTipData'])

const form: any = ref(null)

const getFormTips = () => {
  if (props.tip) {
    const tipMarks: any = FORMTIPS[props.tip]
    const postData = Object.values(tipMarks)
    getTips({ codeList: postData }).then((res: any) => {
      if (res.success && res.data) {
        const tipData: any = {}
        for (var i in tipMarks) {
          const data = res.data.find((r: any) => { return r.code === tipMarks[i] })
          tipData[i] = ({ tip: i, code: tipMarks[i], text: data?.text ?? '' })
        }
        emit('update:modelValue', tipData)
      }
    })
  }
}

getFormTips()

const handleSubmit = () => {
  form.value.submit()
}

const onSubmit = () => {
  emit('postData')
}

const validate = () => {
  return new Promise((resolve) => {
    resolve(form.value.validate())
  })
}

defineExpose({
  handleSubmit,
  validate
})

</script>
