import request from '@/utils/request'

// 获取labelTips列表
export function getTips(data: any) {
  return request({
    url: '/tip/getList',
    method: 'post',
    data
  })
}

// 更新tips
export function updateTip(data: any) {
  return request({
    url: '/tip/modify',
    method: 'post',
    data
  })
}
