const tokenKey = 'token'
const xTokenKey = 'x-token'
const userKey = 'user'
const tokenObj = 'token-obj'

export function getToken() {
  return window.localStorage.getItem(tokenKey)
}

export function getXToken() {
  return window.localStorage.getItem(xTokenKey)
}

export function setTokenObjective(obj: any) {
  if (obj && obj.data && obj.data.expiredAt) {
    window.localStorage.setItem(tokenObj, obj.data.expiredAt.toString())
  }
}

export function getTokenObjective(): number {
  return Number(window.localStorage.getItem(tokenObj)) || 0
}

export function setToken(token: string) {
  window.localStorage.setItem(tokenKey, token)
}

export function setXToken(xtoken: string) {
  window.localStorage.setItem(xTokenKey, xtoken)
}

export function removeToken() {
  window.localStorage.removeItem(tokenKey)
}

export function removeXToken() {
  window.localStorage.removeItem(xTokenKey)
}

export function setUserInfo(user: object) {
  window.localStorage.setItem(userKey, JSON.stringify(user))
}

export function getUserInfo() {
  const userInfo = window.localStorage.getItem(userKey)
  if (userInfo) {
    return JSON.parse(userInfo)
  } else {
    return ''
  }
}

export function removeUserInfo() {
  window.localStorage.removeItem(userKey)
}

export function validateToken() {
  const token = getToken()
  const tokenExpires = getTokenObjective()
  if (token && tokenExpires && tokenExpires >= new Date().getTime()) {
    return true
  } else {
    return false
  }
}
