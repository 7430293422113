import { menuType } from '@/enums'
// 个人中心页面
// const userAccount = []

export const generatorDynamicRouter = (data: any) => {
  return new Promise((resolve) => {
    const resNav = data
    const menuNav: any[] = []
    const childrenNav: any[] = []

    listToTree(resNav, childrenNav, null)

    // 个人中心静态路由
    // listToTree(userAccount, childrenNav, 0)

    childrenNav.forEach((x) => {
      menuNav.push(x)
    })

    const routers = generator(menuNav)

    resolve(routers)
  }).catch((err) => {
    return Promise.reject(err)
  })
}

export const generator = (routerMap: any) => {
  return routerMap.map((item: any) => {
    const {
      title,
      target,
      icon,
      link
    } = item.meta || {}
    const currentRouter: any = {
      app: item.app,
      path: item.path,
      name: item.code,
      component: null,
      meta: {
        title: title,
        icon: icon || undefined,
        target: target,
        link: link
      },
      children: [],
      hidden: item.hidden
    }

    if (item.type === menuType.DIR) {
      // 菜单类型是目录
      if (item.pid == null) {
        // 父级ID是null，最高级菜单，组件layout
        currentRouter.component = '/layout/layout.vue'
      } else {
        // 父级ID不是null，中间层目录，组件 router-view
        currentRouter.component = ''
      }
    } else if (item.type === menuType.MENU) {
      // 菜单类型是菜单
      if (item.pid != null) {
        // 父级ID不是null，组件是对应页面
        currentRouter.component = item.component
      } else {
        // 父级ID是NULL,应用下的菜单，需要外部组件layout
        currentRouter.path = ''
        currentRouter.children.push({
          app: item.app,
          path: item.path,
          name: item.code,
          component: item.component,
          meta: {
            title: title,
            icon: icon || undefined,
            target: target,
            link: link
          }
        })
      }
    }

    // // 是否设置了隐藏菜单
    // if (show === false) {
    //   currentRouter.hidden = true
    // }
    // // 是否设置了隐藏子菜单
    // if (hideChildren) {
    //   currentRouter.hideChildrenInMenu = true
    // }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children)
    }
    return currentRouter
  })
}

/**
 * 递归获得树状结构
 * @param {*} 菜单数组
 * @param {*} 树
 * @param {*} 父级id
 */
const listToTree = (list: any, tree: any, parentId: any) => {
  if (list && list.length > 0) {
    list.forEach((item: any) => {
      if (item.pid === parentId) {
        const child = {
          ...item,
          key: item.key || item.id,
          children: []
        }
        // 迭代 list， 找到当前菜单相符合的所有子菜单
        listToTree(list, child.children, item.id)
        // 删掉不存在 children 值的属性
        if (child.children.length <= 0) {
          delete child.children
        }
        // 加入到树中
        tree.push(child)
      }
    })
  }
}
