export const FORMTIPS: any = {
  DeliveryApply: {
    chooseNature: '682EF170-AAE9-4746-876C-132E59244F28',
    useReason: '0853C25C-A430-4C16-8ADE-09F2F100D028',
    applicantId: 'DCAC200B-41F6-1D07-F4FC-650D348B4F9C',
    signedOrgId: 'D4C45807-4FCD-662C-60B4-E173544C5B05',
    contractId: 'B7BE9131-82E7-FEAB-0C54-DD1608B7677D',
    purchaseNumber: '29ED8CAA-06DB-59E4-8556-91A52D45EC8B',
    isHospitalTrial: '1D2642D9-F6D9-EBAA-80E5-876E8AD80D4A',
    usingOrgId: 'C734027D-953C-FA35-E9E9-698FE27BB2C1',
    usingDeptId: '559EB773-418C-9187-9966-8CC1DFA217E5',
    expectDeliveryDate: 'CD9072B3-8B4E-81CD-DA94-AC028CC24034',
    logisticsWay: 'D6E6D36C-3D94-A652-6E64-C3C9E4DFA7',
    takerId: '9F24DF4A-7ABA-1862-64FB-CC748690F50D',
    receiverOrgId: 'C3DB5A4E-CE5F-E3D1-CA95-3F3ABEB090C3',
    receiverId: '2F39255C-553B-6EAB-C2B0-640107185393',
    receiverMobile: '8E36D615-243B-602F-B50D-73363807FAFD',
    receiverAddress: '76E7DCDB-0AD1-CB7A-965A-762BE298942C',
    billReceiverOrgId: 'C1797DDB-7E21-A7B7-048C-F906B3416B18',
    billReceiverId: '3FB27251-B084-E63C-B41A-F7F6BB64ED94',
    billReceiverMobile: '38746D95-E9ED-BAAB-427B-BDA477E9E522',
    billReceiverAddress: '7F7664BE-583B-FD61-9BBC-6217D2F6DC9B',
    planNature: '012CC0FC-BEE0-E6E2-83BB-59A20706CCB0'
  }
}
