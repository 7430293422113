/**
 * 向后端请求用户的菜单，动态生成路由
 */
import { constantRouterMap } from '@/router/router.config'
import { generatorDynamicRouter } from '@/router/generator-routers'
import { getUserInfo } from '@/utils/auth'
const getDefaultState = () => {
  return {
    routers: constantRouterMap,
    addRouters: []
  }
}

const state = getDefaultState()

const mutations = {
  SET_ROUTERS: (state: any, routers: any) => {
    state.addRouters = routers
    state.routers = constantRouterMap.concat(routers)
  }
}

const actions = {
  async generateRoutes({ commit }: any) {
    try {
      return new Promise((resolve) => {
        const userInfo = getUserInfo()
        const menus = userInfo.menus
        if (state.addRouters.length <= 0 || state.addRouters.length !== menus.length) {
          generatorDynamicRouter(menus).then((routers) => {
            commit('SET_ROUTERS', routers)
            resolve(routers)
          })
        } else {
          resolve(null)
        }
      })
    } catch (err) {
      // eslint-disable-next-line no-undef
      console.log(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

