import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getUserInfo as getUser, validateToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'
// import { getWxSdk } from '@/utils/wxsdk'
import * as dd from 'dingtalk-jsapi'
NProgress.configure({ showSpinner: false }) //

let routeFlag = false

router.beforeEach(async(to: any, from: any, next: any) => {
  NProgress.start()
  if (dd.env.platform !== 'notInDingTalk') {
    dd.ready(() => {
      dd.biz.navigation.setTitle({
        title: getPageTitle(to.meta.title)
      })
    })
  } else {
    document.title = getPageTitle(to.meta.title)
  }

  if ((to && to.meta && to.meta.isWhiteList === true) || to.path.indexOf('login') > -1 || routeFlag) {
    next()
  } else {
    // 路由获取的code
    const ddCorpId: string = to.query.code
    // 钉钉首次打开，重新登录
    if (ddCorpId && dd.env.platform !== 'notInDingTalk') {
      const code = await dingGetCode(ddCorpId)
      const result = await store.dispatch('user/dingLogin', code)
      routeFlag = true
      if (result.success) {
        await store.dispatch('user/getInfo')
        // 获取动态路由
        const accessRoutes = await store.dispatch('permission/generateRoutes')

        if (accessRoutes && accessRoutes.length > 0) {
          // 添加到路由
          router.options.routes = router.options.routes.concat(accessRoutes)
          addRouters(accessRoutes)
        }
        next({ ...to, replace: true })
      } else {
        next('/404')
      }
    } else {
      const user = getUser()
      const hasUserInfo = store.getters.info ?? null
      if (validateToken()) {
        if (hasUserInfo) {
          next()
        } else {
          await store.dispatch('user/getInfo')
          // 获取动态路由
          const accessRoutes = await store.dispatch('permission/generateRoutes')

          if (accessRoutes && accessRoutes.length > 0) {
            // 添加到路由
            router.options.routes = router.options.routes.concat(accessRoutes)
            addRouters(accessRoutes)
          }
          routeFlag = true
          next({ ...to, replace: true })
          if (!user && to.matched.length === 0) {
            router.push({ path: to.path, query: to.query })
          }
        }
      } else {
        routeFlag = true
        next('/404')
      }
    }
  }
})

router.afterEach(() => {
  // 页面回滚
  window.scrollTo(0, 0)
  NProgress.done()
})

const dingGetCode = async(ddCorpId: string) => {
  let code = ''
  const params = {
    corpId: ddCorpId,
    onSuccess: (result: any) => {
      code = result.code
    },
    onFail: (err: any) => {
      store.dispatch('user/resetToken')
      alert(JSON.stringify(err))
    }
  }
  await dd.runtime.permission.requestAuthCode(params)
  return code
}

const addRouters = (routers: any, parentName = '') => {
  routers.forEach((item: any) => {
    if (item.path && item.component) {
      const componentString = item.component.replace(/^\/+/, '') // 过滤字符串前面所有 '/' 字符
      const componentPath = componentString.replace(/\.\w+$/, '')
      const route = {
        path: item.path,
        redirect: item.redirect,
        name: item.name,
        component: () => import('@/views/' + componentPath + '.vue'),
        meta: item.meta
      }
      parentName ? router.addRoute(parentName, route) : router.addRoute(route)
      if (item.children && item.children.length) {
        addRouters(item.children, item.name)
      }
    }
  })
}

