/**
 * 流程节点datacategory
 */
const DATACATEGORY: any = {
  HOLTERACCOUNTAPPLICATION: 10, // 长程账号申请
  PRODTASK_V2: 17, // 生产任务
  PERFORMANCETEST: 20, // 性能检测-生产
  PACKAGING: 21, // 包装
  THROUGH: 22, // 放行
  PUTINSTORAGE: 23, // 入库
  CONTRACTAPPROVE: 26, // 合同审批
  DELIVERYAPPLICATION_V2: 27, // 发货需求申请
  DELIVERY: 28, // 新业坊发货
  COMPLETEDELIVERY: 29, // 上传随货同行单
  AFTERSALEAPPLICATION: 31, // 售后服务申请
  AFTERSALESUGGESTION: 32, // 处理意见
  AFTERSALEDECISION: 33, // 处理决定
  EDITREPAIRRESULT: 34, // 录入检修结果
  INVOICEAPPLICATION: 35, // 发票申请
  MAKEINVOICE: 36, // 开票
  CONFIRMBACK: 37, // 确认返厂
  AFTERSALEDETECT: 38, // 性能检测-检修
  RECYCLE: 39, // 备用机回收
  CONFIRMASSEMBLECOMPLETE: 40, // 组装
  CUSTOMERCHANNELAPPROVE: 41, // 客户/企业审核
  MIDDLESTORAGE: 42, // 中间库
  DEVICERECYCLE: 43, // 设备回收
  DEVICERECYCLETEST: 44, // 设备回收-性能检测
  HEALTHACCOUNT: 45, // 健康管家账号申请 HEALTH
  AFTERSALEAPPLICATIONV2: 46, // 售后服务申请V2
  CONFIRMDELIVERY: 47, // 确认计划
  IMPROVEDELIVERY: 48, // 完善发货申请
  DELIVERY2: 49, // 真陈路发货
  COMPLEMENTPRODTASK: 50, // 发布生产任务
  PRODREQUIRED: 51// 生产需求
}

/**
 * 选择器类型
 */
const PICKERTYPE: any = {
  PERSONNEL: 1,
  ORG: 2,
  CONTRACT: 3
}

/**
 * 表单项
 */
const FIELDTYPE: any = {
  SELECT: 1,
  INPUT: 2,
  RADIO: 3,
  DATE: 4,
  DISABLED: 0
}

export default {
  DATACATEGORY,
  PICKERTYPE,
  FIELDTYPE
}
