import request from '@/utils/request'

/**
 * 登录
 * @param {*} data
 * @returns
 */
export function login(data: any) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

/**
 * 登录
 * @param {*} data
 * @returns
 */
export function wxLogin(data: any) {
  return request({
    url: '/wxworkLogin',
    method: 'post',
    data
  })
}
/**
 * 获取登录用户信息
 * @returns
 */
export function getInfo() {
  return request({
    url: `/mobile/getLoginUser/${process.env.VUE_APP_CODE}`,
    method: 'get'
  })
}
/**
 * 钉钉登录
 * @param {*} data
 * @returns
 */
export function dingLogin(data: any) {
  return request({
    url: '/dingTalkLogin',
    method: 'post',
    data
  })
}
