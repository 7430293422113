import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import XForm from '@/components/XForm.vue'
import constVariable from './constVariable'

import 'vant/lib/index.css'

// 引入全局样式
import './assets/style/index.less'
// 移动端适配
import 'amfe-flexible'

import '@/permission' // permission control

const app = createApp(App)
// 注册全局方法
app.config.globalProperties.$constVariable = constVariable

// 全局组件
app.component('XForm', XForm)

// 引用vant
app.use(Vant)
app.use(store).use(router).mount('#app')
