/**
 * @description [ axios 请求封装]
 */
import axios, { AxiosResponse } from 'axios'
import router from '.././router'
import { showLoadingToast, showFailToast } from 'vant'
import store from '@/store'
// 根据环境不同引入不同api地址
import { getToken, getXToken } from '@/utils/auth'
const service: any = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: 'http://192.168.0.62:8000',
  timeout: 10000
})

let loading: any
const startLoading = () => {
  loading = showLoadingToast({
    duration: 0, // 持续展示 showToast
    forbidClick: true,
    message: '加载中...'
  })
}

const endLoading = () => {
  loading.close()
}

let needLoadingRequestCount = 0
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) startLoading()
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) endLoading()
}

/**
 * 请求拦截
 */
service.interceptors.request.use(
  (config: any) => {
    if (store.getters.token) {
      if (!config) {
        config = {}
      }
      if (config.needLoading === undefined || config.needLoading) {
        showFullScreenLoading()
      }
      if (!config.headers) {
        config.headers = {}
      }
      // 添加授权token
      config.headers['Authorization'] = 'Bearer ' + getToken()

      // 添加刷新令牌token
      config.headers['x-access-token'] = getXToken() || ''
    }
    // showLoading()
    return config
  },
  (error: any) => {
    console.log(error)
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */
service.interceptors.response.use(
  async(response: AxiosResponse) => {
    tryHideFullScreenLoading()
    // hideLoading()
    if (response.request.responseType === 'blob') {
      return response
    }
    const resData = response.data
    if (!resData.success) {
      if (resData.code === 401) {
        router.push('/404')
      }
      showFailToast(resData.msg)
    }

    return resData
  },
  (error: any) => {
    tryHideFullScreenLoading()
    console.log(error)
    return Promise.reject(error)
  }
)

export default service
