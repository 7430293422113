import { createRouter, createWebHistory } from 'vue-router'
import { constantRouterMap } from '@/router/router.config'

// const originalPush = Router.prototype.push
// Router.prototype.push = function push (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }

const scrollDefault: any = { y: 0 }

const createRouterDefault = () =>
  createRouter({
    history: createWebHistory(),
    scrollBehavior: () => (scrollDefault),
    routes: constantRouterMap
  })

const router = createRouterDefault()

export function resetRouter() {
  const newRouter = createRouterDefault()
  router.resolve = newRouter.resolve // reset router
}

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: () => (scrollDefault),
  routes: constantRouterMap
})
