<template>
  <div>
    <router-view />
  </div>
</template>

<style>
/* @import '/style/extend.scss'; */
@import './style/extend.scss';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
  --van-tag-line-height: 20px;
  min-height: 100vh !important;
}

</style>
